import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import twitter from "../../Assets/Projects/twitter.jpg";
import webrecord from "../../Assets/Projects/webrecord.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={twitter}
              isBlog={false}
              title="X.com Clone"
              description="X.com Clone build with Tailwind CSS and Javascript. It is responsive and have different div elements and items as the actual twitter homepage."
              ghLink="https://github.com/md24anas/x.com-clone"
              demoLink="https://twitter.anasmohammad.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={webrecord}
              isBlog={false}
              title="Real-time recorder"
              description="This is a real-time webcam recorder build with React.js and hosted on firebase. It records and gives an option to download the recorded video."
              ghLink="https://github.com/md24anas/webcam"
              demoLink="https://webc-8e84b.web.app/"
            />
          </Col>

          
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
